<template>
    <div class="container">
        <div class="tel-pic">

        </div>
        <div class="contact-info">
            <h5>{{name}}</h5>
            <p>{{phone}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ContactPhone",
        props:['name','phone']
    }
</script>

<style lang="stylus" scoped>
.container
    width 100%
    display flex
    flex-direction row
    height 5rem
    padding 0.5rem 0.5rem
    .tel-pic
        width 5rem
        height 100%
        background url("../../assets/images/tel.png")
        background-size 100% 100%
    .contact-info
        flex-grow 1
        box-sizing border-box
        padding-left 0.5rem
        h5
            margin 0.2rem 0
            box-sizing border-box
            font-size 1.2rem
            font-family Adobe Heiti Std R, Adobe Heiti Std R-R
            color #333333
            font-weight: R;
        p
            margin 0
            padding 0
            font-size 1.6rem
            color #123367
            font-weight: 700;
</style>