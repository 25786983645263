<template>
    <div>
        <newHeader url="/AboutUs"></newHeader>
        <div class="title-box-1">
            COMPANY PROFILE
        </div>
        <div class="title-box-2">
            <img src="../assets/images/compantprofile.png" height="30" width="123" class="img-title"/>
            <div class="img-title">
            </div>
        </div>
        <div style="background-color: #f4f4f4;z-index:-1000;position: sticky">
            <div class="container-box">
                <div class="profile">
                    <el-image :src="require('@/assets/images/aboutUs01.png')"></el-image>
                    <div class="profileinfo">
                        <h2>
                            <el-image :src="require('@/assets/images/quotes01.png')"></el-image>
                            重庆泷通科技有限公司
                            <el-image :src="require('@/assets/images/quotes02.png')"></el-image>
                        </h2>
                        <p>成立于2020年12月，注册资金3000万元，为中小城配企业服务，围绕货、人、车、能源、金融等关键要素，打造产业融合式城配新生态，拥有自主知识产权架构，百万级API接口数据处理能力，针对城配项目结算和追踪智能化，专注汽车管理系统研发、汽车金融及运营产品、政府集采项目，与多家汽车整车生产企业、银行、担保公司、金融机构建立了长期稳定的战略合作关系。车源方面与长安汽车、厦门金龙、柳州五菱、上汽大通、东风柳汽、宗申签订战略合作协议。物流业务方面现运营重庆京东城配、谊品生鲜、云南安得京东仓、云南安得成都仓、云南安得杨林仓、云南云所等项目。政府采购方面,我司新能源事业部先后成功中标，藏区2021年五个县农村客运线班车采购、拉萨交通产业集团100辆新能源出租车，200台斯柯达出租车采购、西藏移动公司宣传车、各地公安局改装车、特警突车等多个项目。2021年营业收入已突破一个亿，聚合中小城配企业513家，注册直接运力司机43596名，业务覆盖区域包括但不限于重庆、成都、贵阳、拉萨、昆明，预计2022年注册企业2000家，可控运力11万，营业收入将达到五个亿。
                        </p>
                        <p>2021年12月底，重庆泷通科技有限公司与民生物流有限公司拟建立以IPO上市为目的的“民生泷通科技公司”负责民生泷通新能源城配聚合平台的运营，合资公司项目已过双方董事会，并通过国资委线上系统审批，现合资公司正在加速组建中。
                        </p>
                    </div>
                </div>
            </div>
            <div class="title-box-1">
                PARTNER
            </div>
            <div class="title-box-2">
                <img src="../assets/images/partner.png" height="30" width="122" class="img-title"/>
                <div class="img-title">
                </div>
            </div>
            <div class="partner">
                <div class="container-box">
                    <div class="link-info" v-for="item in Links" :key="item.name">
                        <el-card @click.native="item.url" >
                            <el-image :src="item.pic" style="width: 200px;height: 110px"/>
                        </el-card>
                    </div>
                </div>
            </div>
            <div class="title-box-1">
                CONTACT INFOMARTION
            </div>
            <div class="title-box-2">
                <img src="../assets/images/contactinformation.png" height="29" width="125" class="img-title"/>
                <div class="img-title">
                </div>
            </div>
            <div class="contacts">
                <baidu-map class="map" :center="{lng: 106.55, lat: 29.548}" :zoom="15" style="width: 1200px;height: 280px">
                    <bm-marker :position="{lng: 106.559991, lat: 29.541651}" :icon="{url: pointIcon, size: {width: 34, height: 34}}">
                    </bm-marker>
                    <bm-overlay
                            ref="myInfo"
                            pane="labelPane"
                            class="sample"
                            @draw="draw"
                            show="true"
                            :position="{lng: 106.559991, lat: 29.541651}"
                            @mouseover.native="active = true"
                            @mouseleave.native="active = false">
                        <div>
                            <el-image :src="require('@/assets/images/contacts.png')"/>
                            <span style="margin-left: 5px;">联系人：曾美菊</span>
                        </div>
                        <div>
                            <el-image :src="require('@/assets/images/phone.png')"/>
                            <span style="margin-left: 5px;">电 话：62315009</span>
                        </div>
                        <div>
                            <el-image :src="require('@/assets/images/location.png')"/>
                            <span style="margin-left: 5px;">地 址：重庆市南岸区铜元局江苑路8号英华天元6栋2楼</span>
                        </div>
                    </bm-overlay>
                </baidu-map>
            </div>
        </div>

        <buttom></buttom>
    </div>
</template>

<script>
    import newHeader from '@/components/common/newHeader'
    import Header from '@/components/common/Header'
    import Buttom from "@/components/common/Buttom";
    import SearchBox from "@/components/common/SearchBox";
    import ContactPhone from "@/components/common/ContactPhone";
    import Swipper from "@/components/common/index/Swipper";

    export default {
        name: "AboutUs",
        data: function () {
            return {
                menus: [],
                title: '公司介绍',
                content: 'COMPANT PROFILE',
                pointIcon: require('@/assets/images/point.png'),
                Links: [
                    {
                        url: "http://www.baidu.com",
                        pic: require('@/assets/images/partner/partner_mswl.png')
                    },
                    {
                        url: "http://www.baidu.com",
                        pic: require('@/assets/images/partner/partner_gsj.png')
                    }
                ]
            }
        },
        components: {
            Header,
            Buttom,
            SearchBox,
            ContactPhone,
            Swipper,
            newHeader
        },
        created() {
            this.initMenus();
        },
        methods: {
            draw({el, BMap, map}) {
                const pixel = map.pointToOverlayPixel(new BMap.Point(106.559991,29.541651))
                el.style.left = pixel.x - 180 + 'px';
                el.style.top = pixel.y - 140 + 'px';
            }
        }
    }
</script>

<style lang="stylus" scoped>
    .banner, .container-box
        margin 0 auto

    .banner
        width 100%
        height 35rem
        background-color #a4a5a0
        background url("../assets/images/banner3.png")
        background-size 100% 100%

    .container-box
        width 123rem
        margin-top 3rem
        padding-top 40px
        text-align center
    .title
        text-align -webkit-center
        margin 85px 0 48px 0

    .profile
        margin 15px 0px 50px 0px
        width 1200px

        el-image:first-child
            max-width 50%

        p
            font-size 14px
            color #999999
            margin 30px

        h2
            font-size 24px
            color #333333
            margin 30px

        .profileinfo
            width 50%
            display block
            float right
            margin-top -50px

    .partner
        width 100%
        height 35rem
        background-color #000b25
        background url("../assets/images/aboutUs02.png")
        background-size 100% 100%

        .link-info
            display: inline-block;
            .el-card
                width: 240px;
                height 150px
                margin 30px 65px 30px 0px

    .contacts
        text-align -webkit-center
        margin-bottom 50px

    .sample {
        background-image: url("../assets/images/aboutUsInfo.png");
        width: 360px;
        height: 120px;
        line-height: 20px;
        background-repeat: no-repeat;
        overflow: hidden;
        color: #fff;
        padding: 20px;
        position: absolute;
        background-position: bottom;
    }

    .title-box-1 {
        height: 200px;
        font-size: 52px;
        color: #ededed;
        line-height: 200px;
        z-index: -999;
        position: absolute;
        width: 100%;
        letter-spacing: 5px;
        text-align: center;
    }

    .title-box-2 {
        padding: 65px 0;
        text-align: center;
    }

    .img-title:after {
        content: '';
        width: 50px;
        height: 1px;
        display: block;
        margin: 0 auto;
        border-bottom: 3px solid #1a4fcd;
    }
    >>> .anchorBL {
        display none
    }
    @media screen and (min-width: 320px)  and (max-width: 1400px)
        .container-box
            width 100%

    .tel-pic
        color #000088
</style>
